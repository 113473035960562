import { default as indexqVzMGXMBz5Meta } from "/home/black/project/me/danny.portofolio/pages/about/index.vue?macro=true";
import { default as personalQIbUBMynyNMeta } from "/home/black/project/me/danny.portofolio/pages/about/personal.vue?macro=true";
import { default as toolboxi4OOlOI3ZyMeta } from "/home/black/project/me/danny.portofolio/pages/about/toolbox.vue?macro=true";
import { default as work2zHVAUYxGoMeta } from "/home/black/project/me/danny.portofolio/pages/about/work.vue?macro=true";
import { default as adminfY9PSObBJqMeta } from "/home/black/project/me/danny.portofolio/pages/admin.vue?macro=true";
import { default as apirf4MNB18O3Meta } from "/home/black/project/me/danny.portofolio/pages/api.vue?macro=true";
import { default as blogLdnLMOrJBtMeta } from "/home/black/project/me/danny.portofolio/pages/blog.vue?macro=true";
import { default as guest_ideaAwdJ5VWRglMeta } from "/home/black/project/me/danny.portofolio/pages/guest_idea.vue?macro=true";
import { default as guestUUNX7pXegNMeta } from "/home/black/project/me/danny.portofolio/pages/guest.vue?macro=true";
import { default as GuestPageQ6rOZFGPmSMeta } from "/home/black/project/me/danny.portofolio/pages/GuestPage.vue?macro=true";
import { default as homeAwQfmo64JGMeta } from "/home/black/project/me/danny.portofolio/pages/home.vue?macro=true";
import { default as index7qyEdspKtqMeta } from "/home/black/project/me/danny.portofolio/pages/index.vue?macro=true";
import { default as command13YnTF60tFMeta } from "/home/black/project/me/danny.portofolio/pages/project/command.vue?macro=true";
import { default as indexcYCUypBL33Meta } from "/home/black/project/me/danny.portofolio/pages/project/index.vue?macro=true";
import { default as previewYdw6p7fdS8Meta } from "/home/black/project/me/danny.portofolio/pages/project/preview.vue?macro=true";
import { default as securityepEkpTTnbnMeta } from "/home/black/project/me/danny.portofolio/pages/project/security.vue?macro=true";
import { default as webnk8M4MecsMMeta } from "/home/black/project/me/danny.portofolio/pages/project/web.vue?macro=true";
import { default as registerLfojF8hQzNMeta } from "/home/black/project/me/danny.portofolio/pages/register.vue?macro=true";
import { default as terminalK06abjFg4IMeta } from "/home/black/project/me/danny.portofolio/pages/terminal.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/home/black/project/me/danny.portofolio/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: "about-personal",
    path: "/about/personal",
    component: () => import("/home/black/project/me/danny.portofolio/pages/about/personal.vue").then(m => m.default || m)
  },
  {
    name: "about-toolbox",
    path: "/about/toolbox",
    component: () => import("/home/black/project/me/danny.portofolio/pages/about/toolbox.vue").then(m => m.default || m)
  },
  {
    name: "about-work",
    path: "/about/work",
    component: () => import("/home/black/project/me/danny.portofolio/pages/about/work.vue").then(m => m.default || m)
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/home/black/project/me/danny.portofolio/pages/admin.vue").then(m => m.default || m)
  },
  {
    name: "api",
    path: "/api",
    component: () => import("/home/black/project/me/danny.portofolio/pages/api.vue").then(m => m.default || m)
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/home/black/project/me/danny.portofolio/pages/blog.vue").then(m => m.default || m)
  },
  {
    name: "guest_idea",
    path: "/guest_idea",
    component: () => import("/home/black/project/me/danny.portofolio/pages/guest_idea.vue").then(m => m.default || m)
  },
  {
    name: "guest",
    path: "/guest",
    component: () => import("/home/black/project/me/danny.portofolio/pages/guest.vue").then(m => m.default || m)
  },
  {
    name: "GuestPage",
    path: "/GuestPage",
    component: () => import("/home/black/project/me/danny.portofolio/pages/GuestPage.vue").then(m => m.default || m)
  },
  {
    name: "home",
    path: "/home",
    component: () => import("/home/black/project/me/danny.portofolio/pages/home.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/black/project/me/danny.portofolio/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "project-command",
    path: "/project/command",
    component: () => import("/home/black/project/me/danny.portofolio/pages/project/command.vue").then(m => m.default || m)
  },
  {
    name: "project",
    path: "/project",
    component: () => import("/home/black/project/me/danny.portofolio/pages/project/index.vue").then(m => m.default || m)
  },
  {
    name: "project-preview",
    path: "/project/preview",
    component: () => import("/home/black/project/me/danny.portofolio/pages/project/preview.vue").then(m => m.default || m)
  },
  {
    name: "project-security",
    path: "/project/security",
    component: () => import("/home/black/project/me/danny.portofolio/pages/project/security.vue").then(m => m.default || m)
  },
  {
    name: "project-web",
    path: "/project/web",
    component: () => import("/home/black/project/me/danny.portofolio/pages/project/web.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    component: () => import("/home/black/project/me/danny.portofolio/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "terminal",
    path: "/terminal",
    component: () => import("/home/black/project/me/danny.portofolio/pages/terminal.vue").then(m => m.default || m)
  }
]